import useCssUtil from "@hooks/use-css-util"
import { Container, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import GoToAppButton from "./GoToAppButton"
import StepBlock from "./StepBlock"

import Step1Icon from "@svg/email-open-icon.svg"
import Step2Icon from "@svg/bitpay-icon.svg"
import Step3Icon from "@svg/user-group-icon.svg"
import Step4Icon from "@svg/payout-icon.svg"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    backgroundColor: theme.custom.lightSectionBgColor,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  stepContainer: {
    marginTop: 50,
    [theme.breakpoints.up("sm")]: {
      marginTop: 80,
    },
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "center",
  },
  description: {
    fontSize: "18px",
  },
}))

export default function HowItWorks() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.container}>
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={cssUtil.mb20}
        >
          <FormattedMessage
            defaultMessage="How referrals work"
            id="components.HowReferralWorks.title"
          />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          className={classes.description}
        >
          <FormattedMessage
            defaultMessage="Get started in a few easy steps"
            id="components.HowReferralWorks.description"
          />
        </Typography>
      </Container>

      <Container maxWidth="xl" className={classes.stepContainer}>
        <Grid container spacing={8}>
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Enter your email address to get a Referral code"
                id="components.ReferralStepBlock.stepOneDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 1"
                id="components.ReferralStepBlock.stepOneTitle"
              />
            }
            icon={<Step1Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Sign up with BitPay via the link received via email and complete the verification"
                id="components.ReferralStepBlock.stepTwoDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 2"
                id="components.ReferralStepBlock.stepTwoTitle"
              />
            }
            icon={<Step2Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Share your Totenpass referral link with your friends"
                id="components.ReferralStepBlock.stepThreeDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 3"
                id="components.ReferralStepBlock.stepThreeTitle"
              />
            }
            icon={<Step3Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Your referrals will receive free shipping on their first order and you will receive a $20 bitcoin payout."
                id="components.ReferralStepBlock.stepFourDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 4"
                id="components.ReferralStepBlock.stepFourTitle"
              />
            }
            icon={<Step4Icon />}
          />
        </Grid>
      </Container>
    </section>
  )
}
