import React, { ReactNode } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import useCssUtil from "@hooks/use-css-util"
import Typography from "@material-ui/core/Typography"
import Grid, { GridSize } from "@material-ui/core/Grid"

export interface IStepBlockProps {
  content?: ReactNode
  icon?: ReactNode
  title?: ReactNode
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
}

const useCss = makeStyles((theme: CustomTheme) => ({
  StepBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
  StepBlockIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#212131",
    borderRadius: "50%",
    height: 100,
    marginBottom: 30,
    width: 100,
  },
}))

export default function StepBlock(props: IStepBlockProps) {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <Grid
      className={classes.StepBlock}
      item
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
    >
      <div className={classes.StepBlockIcon}>{props.icon}</div>
      <Typography
        variant="h4"
        component="h3"
        align="center"
        color="textPrimary"
        className={cssUtil.mb10}
      >
        {props.title}
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center">
        {props.content}
      </Typography>
    </Grid>
  )
}
