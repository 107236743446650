import { makeStyles } from "@material-ui/core"

const useCssUtil = makeStyles((theme) => ({
  mb5: {
    marginBottom: `5px !important`,
  },
  mb8: {
    marginBottom: `8px !important`,
  },
  mb10: {
    marginBottom: `10px !important`,
  },
  mb12: {
    marginBottom: `12px !important`,
  },
  mb15: {
    marginBottom: `15px !important`,
  },
  mb16: {
    marginBottom: `16px !important`,
  },
  mb20: {
    marginBottom: `20px !important`,
  },
  mb24: {
    marginBottom: `24px !important`,
  },
  mb30: {
    marginBottom: `30px !important`,
  },
  mb32: {
    marginBottom: `32px !important`,
  },
  mb40: {
    marginBottom: `40px !important`,
  },
  mt20: {
    marginTop: `20px !important`,
  },
  mt24: {
    marginTop: `24px !important`,
  },
  mt40: {
    marginTop: `40px !important`,
  },
  textAlignCenter: {
    textAlign: "center !important" as any,
  },
  lgText: {
    fontSize: "18px !important",
  },
  goldText: {
    color: "#d8b170",
  },
}))

export default useCssUtil
