import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"

export const InvisibleReCaptcha = React.forwardRef<() => Promise<string>>(
  function InvisibleReCaptcha(_, ref) {
    const recaptchaRef = React.useRef<ReCAPTCHA | null>(null)
    const [resolve, setresolve] = React.useState<(token: string) => void>()
    const [reject, setreject] = React.useState<() => void>()

    if (ref && typeof ref === "object") {
      ref.current = (): Promise<string> => {
        if (!recaptchaRef.current) {
          console.warn("executing when ref is null")
          return Promise.reject()
        }
        recaptchaRef.current.reset()
        const promise = new Promise<string>((resolve, reject) => {
          setresolve(resolve)
          setreject(reject)
        })
        recaptchaRef.current.execute()
        return promise
      }
    }

    const onRecaptcha = (token: string | null) => {
      if (!token && reject) {
        reject()
      }
      if (token && resolve) {
        resolve(token)
      }
    }

    return (
      <ReCAPTCHA
        size="invisible"
        sitekey="6LfDz7saAAAAANZyF2mKU9C-w1g-VGfq2wdar6vE"
        onChange={onRecaptcha}
        ref={recaptchaRef}
      />
    )
  }
)
