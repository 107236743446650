import React from "react"
import MUIButton, { ButtonProps } from "@material-ui/core/Button"
import clsx from "clsx"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"

export type ButtonSize = ButtonProps["size"] | "xxl"

export interface IButtonProps extends Omit<ButtonProps, "size"> {
  size?: ButtonSize
}

const useCss = makeStyles((theme: CustomTheme) => ({
  ButtonXXL: {
    // styles here
  },
}))

function getSize(size?: ButtonSize): ButtonProps["size"] | undefined {
  switch (size) {
    case "xxl":
      return
    default:
      return size
  }
}

export default function Button(props: IButtonProps) {
  const classes = useCss()
  const { className: nativeClassName, size: nativeSize, ...otherprops } = props

  const className = clsx(
    nativeSize === "xxl" && classes.ButtonXXL,
    nativeClassName
  )
  const size = getSize(nativeSize)

  return React.createElement(MUIButton, {
    className,
    size,
    ...otherprops,
  })
}
