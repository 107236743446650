import { either } from "tiinvo"
import * as client from "./client"

const api = client.create(client.baseURL(`/api/referral`))

const referralrequest = (id: string) => api.post(`/refer/${id}`)

const joinrequest = (recaptcha: string, email: string) =>
  api.post<{ link: string }>(
    `/join/${email}`,
    {},
    {
      headers: { "X-Captcha-Code": recaptcha },
    }
  )

const referralhandler = client.mapresponse({
  200: either.right,
  400: either.left,
  401: either.left,
  404: either.left,
  405: either.left,
  415: either.left,
  500: either.left,
})

const joinhandler = client.mapresponse<Error, { link: string }>({
  200: either.right,
  400: either.left,
  500: either.left,
})

export const join = client.withstatushandler(joinhandler)(joinrequest)
export const referral = client.withstatushandler(referralhandler)(
  referralrequest
)
