import Button from "@components/Button"
import { InvisibleReCaptcha } from "@components/InvisibleRecaptcha"
import {
  CircularProgress,
  Container,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import { CustomTheme } from "@theme"
import { useFormik } from "formik"
import { defineMessages, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import React, { useState } from "react"
import { useCopyToClipboard } from "react-use"
import { either, isdefined, option, pass, pipe } from "tiinvo"
import * as yup from "yup"
import * as referral from "../sdk/referral"
import ContentHero from "@components/ContentHero"
import HowReferralWorks from "@components/HowReferralWorks"
import classNames from "classnames"
import useCssUtil from "@hooks/use-css-util"

const useCss = makeStyles((theme: CustomTheme) => ({
  formBlock: {
    display: "flex",
    flexDirection: "column",
    padding: theme.custom.sectionSpacingXs,
    marginTop: theme.custom.sectionSpacingSm,
    marginBottom: theme.custom.sectionSpacingSm,
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0 14px 32px rgba(1,1,13, .1)",
    [theme.breakpoints.up("md")]: {
      padding: theme.custom.sectionSpacingSm,
    },
  },
}))

//#region i18n

const messages = defineMessages({
  email: {
    id: `pages.referral.email`,
    defaultMessage: `Email address`,
  },
  emailrequired: {
    id: `pages.referral.emailrequired`,
    defaultMessage: `Email address is required`,
  },
  invalidemail: {
    id: `pages.referral.invalidemail`,
    defaultMessage: `Invalid email address, a valid email address looks like john.doe@gmail.com`,
  },
  keynotfound: {
    id: `pages.referral.keynotfound`,
    defaultMessage: ``,
  },
  submit: {
    id: `pages.referral.submit`,
    defaultMessage: `Sign Up`,
  },
  yourreferrallink: {
    id: `pages.referral.yourreferrallink`,
    defaultMessage: `You referral link is \n{0}\nClick to copy`,
  },
  copied: {
    id: `pages.referral.copied`,
    defaultMessage: `copied!`,
  },
})

const mapmessage = (messagekey: string | undefined) =>
  messages[messagekey as keyof typeof messages]

//#endregion

const validationSchema = yup.object({
  email: yup.string().required(`emailrequired`).email(`invalidemail`),
})

export default () => {
  const css = useCss()
  const cssUtil = useCssUtil()
  const [state, copytoclipboad] = useCopyToClipboard()
  const recaptchaRef = React.createRef<() => Promise<string>>()
  const [link, setlink] = useState<string | null>(null)
  const updatelink = either.fold(pass, ({ link }: any) => setlink(link))
  const form = useFormik({
    initialValues: {
      email: ``,
    },
    isInitialValid: false,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const recaptcharesult = await recaptchaRef.current!()
      const res = await referral.join(recaptcharesult, values.email)
      updatelink(res)
    },
  })
  const { formatMessage } = useIntl()
  const mapmessagesafe = pipe(
    option.fromfunction(mapmessage),
    option.map(formatMessage),
    option.unwrapOr(undefined)
  )

  return (
    <>
      <ContentHero
        title={
          <FormattedMessage
            defaultMessage="Totenpass Referral Program"
            id="components.referralPageHero.title"
          />
        }
        description={
          <FormattedMessage
            defaultMessage="Enter your email address to sign up to the Totenpass referral program and receive a $20 cryptocurrecy payout each time a friend signs up with your referral link"
            id="components.referralPageHero.description"
          />
        }
      />
      <HowReferralWorks />
      <Container maxWidth="sm">
        <section className={css.formBlock}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            className={cssUtil.mb16}
          >
            <FormattedMessage
              defaultMessage="Sign up to the Totenpass Referral Program"
              id="components.referralPageForm.title"
            />
          </Typography>
          <TextField
            error={isdefined(form.errors.email)}
            label={formatMessage(messages.email)}
            name="email"
            helperText={mapmessagesafe(form.errors.email)}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            value={form.values.email}
          />
          <Button
            color="primary"
            disabled={form.isSubmitting || !form.isValid}
            startIcon={
              form.isSubmitting && (
                <CircularProgress
                  color="secondary"
                  size="12px"
                  variant="indeterminate"
                />
              )
            }
            variant="contained"
            onClick={form.submitForm}
          >
            {formatMessage(messages.submit)}
          </Button>
          <InvisibleReCaptcha ref={recaptchaRef} />
          {!!link && link.length > 0 && (
            <>
              <Typography
                onClick={() => copytoclipboad(link)}
                style={{ cursor: `pointer` }}
              >
                {formatMessage(messages.yourreferrallink, {
                  0: <strong>{link}</strong>,
                })}
              </Typography>
              {!!state.value && (
                <Typography variant="caption">
                  {formatMessage(messages.copied)}
                </Typography>
              )}
            </>
          )}
        </section>
      </Container>
    </>
  )
}
